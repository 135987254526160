import axios from 'axios'
import {SERVER_HOST} from './../common/portConfig'
var instance = axios.create({
    headers: {
        'Authorization': ''
    }
});
instance.interceptors.request.use(
    config => {
        config.headers.Authorization = sessionStorage.getItem('Authorization');
        return config
    }, err => {
        return Promise.reject(err)
    });


// 住宿
export const reqGetAccommodationListPage = params => { return instance.get(`${SERVER_HOST}/admin/accommodation/page`,{ params: params }) };
export const reqAddAccommodation = params => { return instance.post(`${SERVER_HOST}/admin/accommodation/add`, params) };
export const reqEditAccommodation = params => { return instance.post(`${SERVER_HOST}/admin/accommodation/edit`, params) };
export const reqDeleteAccommodation = params => { return instance.delete(`${SERVER_HOST}/admin/accommodation/delete/`+params.id, params) };
export const reqBatchDeleteAccommodation = params => { return instance.post(`${SERVER_HOST}/admin/accommodation/batchdelete/`, params) };
export const reqGetAccommodationOne = params => { return instance.get(`${SERVER_HOST}/admin/accommodation/one`, { params: params }) };

