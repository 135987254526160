<template>
	<el-row class="warp">
		<el-col :span="24" class="warp-breadcrum">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
				<el-breadcrumb-item>内容管理</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/accommodation/list' }">住宿</el-breadcrumb-item>
				<el-breadcrumb-item>查看</el-breadcrumb-item>
			</el-breadcrumb>
		</el-col>

		<el-col :span="24" class="warp-main">
			<el-form ref="form" :model="form" label-width="150px" class="el-form-self">
				<el-row>
					<el-col>
						<el-form-item label="住宿类型:" prop="type">
							<template v-if="form.type == 0">
								酒店
							</template>
							<template v-if="form.type == 1">
								民宿
							</template>
							<template v-if="form.type == 2">
								青旅
							</template>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col>
						<el-form-item label="名称:" prop="name">
							<div class="view-text">{{ form.name }}</div>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col>
						<el-form-item label="简介:" prop="introduction">
							<div class="view-text">{{ form.introduction }}</div>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col>
						<el-form-item label="阅读次数:" prop="readNumber">
							<div class="view-text">{{ form.readNumber}}</div>
						</el-form-item>
					</el-col>
				</el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="点赞人数:" prop="likeCount">
                            <div class="view-text">{{form.likeCount}}</div>
                        </el-form-item>
                    </el-col>
                </el-row>


			  	<el-row>
                    <el-col>
                        <el-form-item label="外链:" prop="outerLinkUrl">
                            <div class="view-text">{{form.outerLinkUrl}}</div>
                        </el-form-item>
                    </el-col>
                </el-row>

				<el-row>
					<el-col>
						<el-form-item label="住址:" prop="address">
							<div class="view-text">{{ form.address }}</div>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col>
						<el-form-item label="联系电话:" prop="contactNumber">
							<div class="view-text">
								{{ form.contactNumber }}
							</div>
						</el-form-item>
					</el-col>
				</el-row>


				<el-row>
					<el-col>
						<el-form-item label="状态:" prop="status">
							<div class="view-text">
								{{ form.status == 0 ? '发布' : '保存' }}
							</div>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col>
						<el-form-item label="列表缩略图片:" prop="indexImageUrl" :disabled="true">
							<el-image style="width: 300px; height: 300px" :src="indexImageUrl"></el-image>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col>
						<el-form-item label="详情页图片:" prop="imageUrl" :disabled="true">
							<el-image style="width: 300px; height: 300px" :src="imageUrl"></el-image>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col :span="24">
						<el-form-item label="内容:">
							<Editor id="tinymce" v-model="form.content" :init="init" :disabled="true"></Editor>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col>
						<el-form-item label="置顶:" prop="isTop">
							<div class="view-text">{{ form.isTop }}</div>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col>
						<el-form-item label="推荐:" prop="recommended">
							<div class="view-text">{{ form.recommended }}</div>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row class="form-buttons">
					<el-form-item class="el-form-item-self">
						<el-button type="primary" @click="toEdit">编辑</el-button>
						<el-button type="primary" @click="$router.push({path: '/accommodation/list',query: {filtersName: $route.query.filtersName,},})">返回</el-button>
					</el-form-item>
				</el-row>

			</el-form>
		</el-col>
	</el-row>
</template>
<script>
import { uploadFileURL, downloadFileURL } from '../../../api/system-api'
import {
	reqGetAccommodationOne,
	reqEditAccommodation,
} from '../../../api/accommodation-api'
import { SERVER_HOST } from '../../../common/portConfig'
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver/theme'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/plugins/code'
import 'tinymce/plugins/print'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/importcss'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/autosave'
import 'tinymce/plugins/save'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/visualchars'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/image'
import 'tinymce/plugins/media'
import 'tinymce/plugins/link'
import 'tinymce/plugins/codesample'
import 'tinymce/plugins/table'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/toc'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/textpattern'
import 'tinymce/plugins/noneditable'
import 'tinymce/plugins/help'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/quickbars'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/media'
import 'tinymce/plugins/indent2em'
import 'tinymce/icons/default/icons'
import '../../../../public/static/tinymce/langs/zh_CN.js'
export default {
	components: {
		Editor,
	},
	data() {
		return {
			filtersName: '',
			editPath: '/accommodation/list',
			uploadFileURL: uploadFileURL,
			downloadFileURL: downloadFileURL,
			indexImageUrl: '',
			imageUrl: '',
			status: '',
			form: {
				id: 0,
				indexImageUrl: '',
				name: '',
				introduction: '',
				isTop: '',
				recommended: '',
				readNumber:'',
				outer_link_url: '',
				status: '',
				publisher: '',
				columns: '',
				content: '',
				isUse: '',
				type: '',
				imageUrl: '',
				outerLink: '',
				contactNumber: '',
				address: '',
				likeCount:''
			},
			init: {
				language_url: '/static/tinymce/langs/zh_CN.js',
				language: 'zh_CN',
				skin_url: '/static/tinymce/skins/ui/oxide',
				content_css:
					'/static/tinymce/skins/content/default/content.css',
				height: 300,
				font_formats:
					'微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;',
				plugins:
					'code print indent2em preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image media link codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars',
				toolbar:
					'undo redo |code bold italic underline strikethrough indent2em | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak charmap | fullscreen  preview print | image media  pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
			},
		}
	},
	methods: {
		toEdit() {
			this.$router.push({
				path: '/accommodation/edit?id=' + this.form.id,
			})
		},
		getAccommodation(id) {
			var that = this
			reqGetAccommodationOne({ id: id }).then((res) => {
				that.form = res.data.data
				if (that.form.videoUrl) {
					that.videoUrlList = [
						{
							url: downloadFileURL + that.form.videoUrl + '/',
							name: that.form.videoName,
						},
					]
				}

				if (res.data.data.imageUrl) {
					this.imageUrl =
						downloadFileURL + res.data.data.imageUrl + '/'
				} else {
					this.imageUrl = ''
				}
				if (res.data.data.indexImageUrl) {
					this.indexImageUrl =
						downloadFileURL + res.data.data.indexImageUrl + '/'
				} else {
					this.indexImageUrl = ''
				}
				that.form = Object.assign({}, res.data.data)
			})
		},
	},
	mounted() {
		let id = this.$route.query.id
		tinymce.init({})
		this.getAccommodation(id)
	},
}
</script>
